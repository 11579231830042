import clsx from "clsx"

import TestimonyImage from '../images/testimony.png'
import { CaretLeft, CaretRight } from "@phosphor-icons/react"
import { Balancer } from "react-wrap-balancer"
import { useEffect, useState } from "react"

const testimonies = [
  {
    author: "Fabiana",
    testimony: (
      <span>
        Quando a Dra Eliete me sugeriu
fazer o programa de Modulação
Intestinal, confesso que fiquei um
pouco apreensiva com esta
sugestão, pois não sabia se eu
conseguiria seguir a dieta
sugerida.
<br /><br />
Mas, tive uma surpresa incrível...
durante o processo observei que
as questões que me
incomodavam (sensação de
estômago inchado, constipação,
má digestão, etc) simplesmente
desapareceram.
<br /><br />
E, a cereja do bolo foi a perda de
5 kg em 2 meses de dieta.
Confesso, que a princípio achei
que não conseguiria cumprir o
proposto, mas depois que senti
os resultados, toda restrição não
teve mais importância!
Com certeza, valeu muito a pena
ter feito a modulação intestinal.
<br /><br />
Obrigada Dra Eliete por ter me
proporcionado isso. Foi um
Sucesso!!
      </span>
    ),
  },
  {
    author: "Silvana",
    testimony: (
      <span>
        Achei o atendimento excelente. Me senti super a vontade e acolhida para expor meus problemas e esclarecer minhas dúvidas.
        <br /><br />
        Gostei das sugestões de cardápio. Pontualidade, muito conhecimento, interesse e acolhimento.
      </span>
    ),
  },
  {
    author: 'Renan',
    testimony: (
      <span>
        A metodologia de trabalho da Dra. Eliete é fenomenal. Com muita organização, materiais de qualidade, fácil comunicação e conteúdo digital acessível em qualquer momento, ela proporciona praticidade e leveza a uma dieta em que muitas vezes pode ser difícil para algumas pessoas.
        <br /><br />
        Após os meus relatos, ela buscou explicar cada particularidade dos meus exames na essência, em seu mínimo detalhe. Como sou vegetariano, já havia passado por vários profissionais que não conseguiram resolver a minha situação. A Dra. literalmente me virou do avesso!
        <br /><br />
        Muito obrigado Dra.!
      </span>
    ),
  },
]

const Testimonies = () => {
  const[testimonyActive, setTestimonyActive] = useState(testimonies[0])
  const[firstTestimonyActive, setFirstTestimonyActive] = useState(false)
  const[lastTestimonyActive, setLastTestimonyActive] = useState(false)

  function handleNextTestimony() {
    const indexOfActualTestimony = testimonies.findIndex(item => item.testimony === testimonyActive.testimony);

    const qtdTestimonies = testimonies.length
    const indexOfNextTestimony = indexOfActualTestimony + 1

    if(indexOfNextTestimony <= qtdTestimonies - 1) {
      setTestimonyActive(testimonies[indexOfNextTestimony]);
    } else {
      setTestimonyActive(testimonies[indexOfActualTestimony]);
    }
  }

  function handlePreviousTestimony() {
    const indexOfActualTestimony = testimonies.findIndex(item => item.testimony === testimonyActive.testimony);

    const indexOfPreviousTestimony = indexOfActualTestimony - 1

    if(indexOfPreviousTestimony >= 0) {
      setTestimonyActive(testimonies[indexOfPreviousTestimony]);
    } else {
      setTestimonyActive(testimonies[indexOfActualTestimony]);
    }
  }

  useEffect(() => {
    const firstTestimony = testimonies[0].testimony
    const lastTestimony = testimonies[testimonies.length - 1].testimony

    if(lastTestimony === testimonyActive.testimony) {
      setLastTestimonyActive(true)
      setFirstTestimonyActive(false)
    }

    if(firstTestimony === testimonyActive.testimony) {
      setFirstTestimonyActive(true)
      setLastTestimonyActive(false)
    } 
  }, [testimonyActive])

  return (
    <main className={clsx("min-h-screen flex flex-1 flex-col justify-center")}>
      <div className="flex flex-col">
        <h1 className="font-alt mb-8 px-6 lg:px-28">O que estão falando sobre o acompanhamento?</h1>

        <div className="flex flex-col md:flex-row justify-between">
          <div className="w-full md:w-1/2 pl-6 lg:pl-28">
            <div>
              <img src={TestimonyImage} alt='Mulher comendo salada de frutas' width={500} placeholder="blur"/>
            </div>
          </div>

          {/* <div className='w-[80%] md:w-[40%] absolute right-0 flex flex-col mt-[80%] md:mt-8'> */}
          <div className='w-[80%] md:w-[40%] flex flex-col justify-start mt-[80%] md:mt-8'>
            <div className="w-full bg-primary-500 h-14 rounded-tl-lg flex items-center pl-8 mb-8">
              <h3 className="text-background-color w-[10%]">{testimonyActive.author}</h3>
              
              <div className="ml-[35%] mb-16 flex flex-row items-center justify-center gap-4">

                {
                  (testimonyActive.testimony === testimonies[testimonies.length - 1].testimony) || (testimonyActive.testimony === testimonies[0].testimony) || lastTestimonyActive && (testimonyActive.testimony !== testimonies[0].testimony) && (testimonyActive.testimony !== testimonies[testimonies.length - 1].testimony) ? <div className="rounded-full h-11 w-11"></div> :
                  <div className="cursor-pointer rounded-full border-[3px] border-primary-500 bg-background-color h-11 w-11 flex items-center justify-center"
                    onClick={handlePreviousTestimony}
                  >
                    <span className="text-primary-600"><CaretLeft size={24} weight="fill" /></span>
                  </div>
                }


                { lastTestimonyActive ? 
                  <div className="cursor-pointer rounded-full border-[3px] border-primary-500 bg-background-color h-24 w-24 flex items-center justify-center"
                  onClick={handlePreviousTestimony}
                >
                  <div className="rounded-full border-[1px] border-primary-500 bg-transparent h-20 w-20 flex items-center justify-center">
                    <span className="text-primary-600">
                      Anterior
                    </span>
                  </div>
                </div>
                :
                <div className="cursor-pointer rounded-full border-[3px] border-primary-500 bg-background-color h-24 w-24 flex items-center justify-center"
                  onClick={handleNextTestimony}
                >
                  <div className="rounded-full border-[1px] border-primary-500 bg-transparent h-20 w-20 flex items-center justify-center">
                    <span className="text-primary-600">
                      Próximo
                    </span>
                  </div>
                </div>
              }

              {
                (testimonyActive.testimony === testimonies[testimonies.length - 1].testimony) || (testimonyActive.testimony === testimonies[0].testimony) || firstTestimonyActive && (testimonyActive.testimony !== testimonies[0].testimony) && (testimonyActive.testimony !== testimonies[testimonies.length - 1].testimony) ? <div className="rounded-full h-11 w-11"></div> :
                <div className="cursor-pointer rounded-full border-[3px] border-primary-500 bg-background-color h-11 w-11 flex items-center justify-center"
                  onClick={handleNextTestimony}
                >
                  <span className="text-primary-600"><CaretRight size={24} weight="fill" /></span>
                </div>
              }
              </div>
            </div>


              <p>
                <Balancer>
                  {testimonyActive.testimony}
                </Balancer>
              </p>

          </div>

        </div>
      </div>
    </main>
  )
}
export default Testimonies