'use client'

import { BeyondTreatmentOption } from "../components/BeyondTreatmentOption"
import PodcastImage from '../images/podcastImage.png'
import EbookImage1 from '../images/ebookImage.png'
import EbookImage2 from '../images/ebookImage2.png'
import WhatsGroupImage from '../images/whatsGroup.png'
import Pri60DiasImage from '../images/PRI60Dias.png'

import SpotifyBackground from '../images/SpotifyLogo.svg'
import WhatsBackground from '../images/WhatsappLogo.svg'
import EbookBackground from '../images/BookBookmark.svg'
import VideoBackground from '../images/VideoLogo.svg'

import clsx from "clsx"
import { Topic } from "../components/Topic"
import Button from "../components/Button"

const BeyondTreatment = () => {
  return (
    <>
    <section id="quero+" className={clsx("flex flex-col justify-center px-6 lg:px-28")}>
      <div className="flex flex-col">
        <h1 className="font-alt mb-16">Para além do tratamento</h1>


      </div>
    </section>

    <section>
        <BeyondTreatmentOption 
          title="Podcast" 
          subtitle="Somos 10% humanos" 
          description={
            <p>
            Oriento você a conquistar a saúde que você DESEJA para ter a vida que você MERECE através da Modulação intestinal.
            </p>
          }
          sourceImage={PodcastImage} 
          widthImage={280}
          buttonText="Ouça no Spotify"
          bgImage={SpotifyBackground}
        />

        <BeyondTreatmentOption 
          title="E-Book" 
          subtitle="Reprograme seu Intestino" 
          description={
            <p>
              São 60 páginas de conteúdo para você usar como guia no dia a dia e acabar de vez com os problemas do intestino de forma natural. Você não precisa imprimir, basta baixar no seu celular e consultar sempre que quiser.
            </p>
          }
          sourceImage={EbookImage1} 
          widthImage={280}
          buttonText="Comprar E-Book"
          bgImage={EbookBackground}
        />

        <BeyondTreatmentOption 
          title="E-Boook" 
          subtitle="Candidíase Sob Controle" 
          description={
            <p>
              Os 3 erros que toda mulher já cometeu e estão impedindo você de se livrar da candidíase de maneira definitiva mesmo que já se alimente bem.
              <br/><br />
              Para obter uma cópia deste E-book gratuitamente, basta responder ao formulário clicando no botão abaixo.
            </p>
          }
          sourceImage={EbookImage2} 
          widthImage={350}
          buttonText="Baixar E-Book"
          bgImage={EbookBackground}
        />

        <BeyondTreatmentOption 
          title="Grupo VIP" 
          subtitle="Nutritudes" 
          description={
            <p>
              Seja você também um(a) Nutritude. Faça parte do Grupo VIP no WhatsApp para conteúdo extra das lives, receitas, e-books e ofertas EXCLUSIVAS para os participantes.
            </p>
          }
          sourceImage={WhatsGroupImage} 
          widthImage={180}
          buttonText="Entrar no Grupo VIP"
          bgImage={WhatsBackground}
        />

        <BeyondTreatmentOption 
          title="Curso" 
          subtitle="PRI60DIAS" 
          description={
            <p>
              O Programa Reprogramando seu Intestino em 60 Dias (PRI60Dias) é sobre como ter uma vida incrível após os 40 anos através da modulação intestinal.
            </p>
          }
          sourceImage={Pri60DiasImage} 
          widthImage={350}
          bgImage={VideoBackground}
        />

        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 px-6 lg:px-28">
            <Topic index={1} text="Recuperar a saúde, energia e disposição"/>
            <Topic index={2} text="Vou mostrar porque o seu corpo não respondeu as tentativas anteriores de dietas, atividade física e outras estratégias que você possa ter usado"/>
            <Topic index={3} text="Conhecer os alimentos e como eles agem no seu corpo"/>
            <Topic index={4} text="Como ter articulações e músculos mais firmes e fortes mesmo após os 40 anos"/>

            <div className="hidden md:flex items-center justify-center mt-16">
              <Button>Entrar na Lista de Espera do Curso</Button>
            </div>
          </div>

          <div className="w-full md:w-1/2 px-6 lg:px-28">
            <Topic index={5} text="Como ter um sono regenerador"/>
            <Topic index={6} text="Como regular as mudanças que seu corpo apresenta ao longo dos anos"/>
            <Topic index={7} text="Iniciar um repertório de receitas práticas, rápidas e testadas pra você que não tem muito tempo"/>

            <div className="md:hidden flex items-center justify-center my-16">
              <Button>Entrar na Lista de Espera do Curso</Button>
            </div>

            <div className="flex flex-col">
              <div className="mb-8">
                <h1 className="text-2xl uppercase mb-2">O que o programa contempla</h1>

                <p>
                  Quatro aulas ao vivo pelo Zoom e acesso ao Grupo WhatsApp pra receber o link das aulas e comunicados importantes. 
                </p>
              </div>

              <div>
                <h1 className="text-2xl uppercase mb-2">O que o programa não contempla</h1>

                <p>
                  Prescrição personalizada da nutricionista. Isso você irá obter somente nos atendimentos INDIVIDUALIZADOS online ou em consultório.
                </p>
              </div>

            </div>
          </div>
        </div>


    </section>
    </>
  )
}
export default BeyondTreatment