import { FAQQuestion } from '../components/FAQQuestion'
import Balancer from 'react-wrap-balancer'

const FAQ = () => {
  return (
    <section id="duvidas" className="min-h-screen px-6 pb-6 md:pt-0 lg:mx-28">
      {/* <div className="w-full flex justify-center left-0 mb-2 -top-[19px]">
      <h1>Atendimento</h1>
    </div> */}
      {/* <div className="absolute -top-[19px] left-0 flex w-full justify-start pl-[10%]">
        <h1>FAQ</h1>
      </div> */}

      <div className="flex flex-col justify-between md:flex-row">
        <div className="mb-12 w-full md:mb-0 md:w-1/2">
          <div className="sticky top-24 w-full md:w-[70%]">
            <h1 className="pb-4 md:pb-8 uppercase text-3xl">
              <Balancer>Tire suas dúvidas sobre o acompanhamento</Balancer>
            </h1>

            <p>
              Ainda com dúvidas?{' '}
              <a
                href="https://api.whatsapp.com/send?phone=+5547988011281&text=Olá,%20Rafaela,%20gostaria%20de%20tirar%20uma%20ou%20mais%20dúvidas%20sobre%20a%20Letrealè."
                target="_blank"
                className="font-medium text-primary-600 underline"
                rel="noreferrer"
              >
                Entre em contato com a nutri pelo WhatsApp
              </a>
            </p>
          </div>
        </div>

        <div className="flex w-full flex-col gap-y-16 md:w-1/2">
          <FAQQuestion
            question="A consulta é somente online?"
            answer={
              <>
                <Balancer>
                  <span>
                    A consulta vai acontecer através de um aplicativo chamado Zoom, ele é um dos melhores, super estável. Para entrar basta clicar no link que a nutri irá enviar. Se você quiser acessar pelo computador basta clicar no link. Se você quiser fazer pelo celular, é só baixar o aplicativo Zoom antes. É super simples de usar.{' '}
                  </span>
                </Balancer>
              </>
            }
          />

          <FAQQuestion
            question="A nutri atende por plano de saúde?"
            answer={
              <>
                <Balancer>
                  <span>
                    Não trabalho com plano de saúde, meu atendimento é bem personalizado e infelizmente não conseguiria manter o alto padrão por convênio. 
                  </span>
                  <br /><br />
                  <span>
                    Mas olha que legal: emito nota fiscal! Assim você pode pedir reembolso para o seu convênio.
                  </span>
                </Balancer>
              </>
            }
          />

          <FAQQuestion
            question="A nutri atende todos os públicos?"
            answer={
              <>
                <Balancer>
                  <span>
                    Somente adultos (homens e mulheres).
                  </span>
                </Balancer>
              </>
            }
          />
        </div>
      </div>
    </section>
  )
}

export default FAQ