import './index.css';
import { FoodProvider } from './context/foods.context';
import Main from './pages/Main';
import { Instagram } from './components/Instagram';
import Intro from './pages/Intro';
import Service from './pages/Service';
import About from './pages/About';
import BeyondTreatment from './pages/BeyondTreatment';
import Testimonies from './pages/Testimonies';
import FAQ from './pages/FAQ';
import { Footer } from './components/Footer';
import { Header } from './components/Header';

function App() {
  return (
    <FoodProvider>
      <div className="flex min-h-screen flex-col">
        <Header />
        <Main />
      </div>

      <Intro />
      <Service />
      <About />
      <BeyondTreatment />
      <Testimonies />
      <Instagram />
      <FAQ />
      <Footer />
    </FoodProvider>
  );
}

export default App;
