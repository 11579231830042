import { InstagramLogo, WhatsappLogo, YoutubeLogo } from '@phosphor-icons/react'
import { Link } from 'react-scroll'

export function Footer() {
  return (
    <footer className="px-6 py-6 lg:mx-28">
      <div className="relative mb-4 flex flex-row items-center justify-center">
        <nav className="flex w-full flex-col items-center justify-center gap-x-8 md:flex-row">
          <ul className="flex items-center justify-center gap-x-2 text-xs md:gap-x-4 md:text-sm">
            <li>
              <a href="https://www.instagram.com/letreale/" target='_blank' rel="noreferrer"><WhatsappLogo size={24} color="#333620" weight="fill" /></a>
            </li>

            <li>
              <a href="https://www.instagram.com/letreale/" target='_blank' rel="noreferrer"><InstagramLogo size={24} color="#333620" weight="fill" /></a>
            </li>

            <li>
              <a href="https://www.instagram.com/letreale/" target='_blank' rel="noreferrer"><YoutubeLogo size={24} color="#333620" weight="fill" /></a>
            </li>
          </ul>
        </nav>
      </div>

      <div className="relative mb-4 flex flex-row items-center justify-center">
        <nav className="flex w-full flex-col items-center justify-center gap-x-8 md:flex-row">
          <ul className="flex items-center justify-center gap-x-2 text-xs md:gap-x-4 md:text-sm">
            <li>
              <Link
                activeClass="active"
                to="acompanhamento"
                spy={true}
                offset={-80} // Sem header -80
                smooth={true}
                duration={1200}
                className="hover:opacity-50 transition-opacity"
                href="/#acompanhamento"
              >
                Acompanhamento
              </Link>
            </li>

            <li>
              <Link
                activeClass="active"
                to="sobre"
                spy={true}
                offset={-40} // Sem header -40
                smooth={true}
                duration={1100}
                className="hover:opacity-50 transition-opacity"
                href="/#sobre"
              >
                Sobre a Nutri
              </Link>
            </li>

            <li>
              <Link
                activeClass="active"
                to="quero+"
                spy={true}
                offset={-90}
                smooth={true}
                duration={1000}
                className="hover:opacity-50 transition-opacity"
                href="/#quero+"
              >
                Quero +
              </Link>
            </li>

            <li>
              <Link
                activeClass="active"
                to="duvidas"
                spy={true}
                offset={-50}
                smooth={true}
                duration={900}
                className="hover:opacity-50 transition-opacity"
                href="/#duvidas"
              >
                Dúvidas
              </Link>
            </li>
          </ul>

          {/* <ul className='flex right-0 gap-x-2 pt-4 md:pt-0'>
            <li>
              <a href="https://www.instagram.com/letreale/" target='_blank'><WhatsappLogo size={24} color="#67081b" weight="fill" /></a>
            </li>

            <li>
              <a href="https://www.instagram.com/letreale/" target='_blank'><InstagramLogo size={24} color="#67081b" weight="fill" /></a>
            </li>
          </ul> */}
        </nav>
      </div>

      <div className="flex flex-col items-center justify-evenly text-xs md:flex-row">
        <span>© 2023 Nutricionista Funcional Eliete Borges. Todos os direitos reservados.</span>
        <span>
          Desenvolvido por:{' '}
          <a
            href="https://www.linkedin.com/in/franciscogazaniga/"
            target="_blank"
            rel="noreferrer"
          >
            Francisco Eduardo
          </a>
        </span>
      </div>
    </footer>
  )
}