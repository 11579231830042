import {
  ReactNode,
  createContext,
  useContext,
  useState,
} from 'react'

interface FoodProps {
  food: string
  text: React.ReactNode
}

interface FoodContextProps {
  food: FoodProps
  handleChangeFood: (foodInput: string) => void
}

const FoodContext = createContext<FoodContextProps>(
  {} as FoodContextProps,
)

interface FoodProviderProps {
  children: ReactNode
}

const foods = [
  {
    food: 'none',
    text: (
    <span>
      <span>À medida que vamos envelhecendo muitas funções no nosso corpo se tornam mais lentas e uma delas é a digestão.</span>
      <br /><br />
      <span>Minha missão é ajudar você a dar adeus a todos os desconfortos provocados pelo desequilíbrio entre quantidade e qualidade dos microorganismos que vivem em seu intestino.</span>
    </span>
    ),
  },
  {
    food: 'Frutas e Verduras',
    text: (
      <span className='text-white'>
        <span>Além das fibras, as frutas e legumes oferecem também vitaminas, minerais e água fundamentais para o equilíbrio da nossa microbiota intestinal.</span>
      </span>
      ),
  },
  {
    food: 'Polifenóis',
    text: (
      <span className='text-white'>
        <span>Os polifenóis sao micronutrientes com ação anti-inflamatória, antioxidante, anti alérgica, hepatoprotetora, antiviral, anticancerígena e moduladores da microbiota intestinal.</span>
        <br /><br />
        <span>Presentes na lentilha, no feijão, na ervilha, na beterraba, na berinjela, nas castanhas, no azeite de oliva, no cacau e nas frutas vermelhas.</span>
      </span>
      ),
  },
  {
    food: 'Água',
    text: (
      <span className='text-white'>
        <span>O consumo de água evita que o seu organismo use a água que está no intestino grosso, com toxinas que deveriam ser eliminadas, o que além de deixar suas fezes ressecadas também retornará essas toxinas para sua corrente sanguínea intoxicando o fígado.</span>
      </span>
      ),
  },
]

const FoodProvider = ({ children }: FoodProviderProps) => {
  const [food, setFood] = useState<FoodProps>(foods[0])

  function handleChangeFood(foodInput: string) {
    const foundIndex = foods.findIndex(item => item.food === foodInput);

    const foundFood = foods[foundIndex];
    setFood(foundFood)
  }

  return (
    <FoodContext.Provider
      value={{
        food,
        handleChangeFood,
      }}
    >
      {children}
    </FoodContext.Provider>
  )
}

const useFood = () => useContext(FoodContext)

export { FoodProvider, useFood }