import { Plus, X } from '@phosphor-icons/react'
import { AnimatePresence, motion, useCycle } from 'framer-motion'
import { ReactNode, useState } from 'react'

interface FAQQuestionProps {
  question: string
  answer: ReactNode
}

const descriptionOpacity = {
  closed: {
    opacity: 0,
  },
  open: { opacity: 1 },
}

const answerBoxOpenCloseTransition = {
  closed: {
    transition: {
      staggerChildren: 0.2,
      staggerDirection: -1,
    },
  },
  open: {
    transition: {
      staggerChildren: 0.2,
      staggerDirection: 1,
    },
  },
}

export function FAQQuestion({ ...props }: FAQQuestionProps) {
  const [isAnswerBoxOpen, setIsAnswerBoxOpen] = useState(false)
  const [animatePlusXIcon, cyclePlusXIcon] = useCycle(
    { rotate: 0 },
    { rotate: 90 },
  )
  const [open, cycleOpenAnswerBox] = useCycle(false, true)

  function handleOpenAnswerBox() {
    if (isAnswerBoxOpen) {
      setIsAnswerBoxOpen(false)
    } else {
      setIsAnswerBoxOpen(true)
    }
    cyclePlusXIcon()
    cycleOpenAnswerBox()
  }

  return (
    <div>
      <button
        className="flex w-full items-center justify-between border-b-2 border-primary-500 py-2"
        onClick={handleOpenAnswerBox}
      >
        <h3 className="text-base font-normal md:text-lg">{props.question}</h3>

        <motion.div
          animate={animatePlusXIcon}
          transition={{
            ease: 'linear',
            duration: 0.4,
            x: { duration: 0.4 },
          }}
        >
          <Plus
            className={isAnswerBoxOpen ? 'hidden' : 'block'}
            size={24}
            color="#3B3D2F"
          />

          <X
            className={isAnswerBoxOpen ? 'block' : 'hidden'}
            size={24}
            color="#3B3D2F"
          />
        </motion.div>
      </button>

      <AnimatePresence>
        {open && (
          <motion.aside
            initial={{ height: 0 }}
            animate={{
              height: 'auto',
            }}
            exit={{
              height: 0,
              transition: { delay: 0.2, duration: 0.3 },
            }}
          >
            <motion.div
              className="container"
              initial="closed"
              animate="open"
              exit="closed"
              variants={answerBoxOpenCloseTransition}
            >
              {isAnswerBoxOpen ? (
                <motion.div variants={descriptionOpacity}>
                  <div className="px-4 py-8">{props.answer}</div>
                </motion.div>
              ) : (
                <></>
              )}
            </motion.div>
          </motion.aside>
        )}
      </AnimatePresence>
    </div>
  )
}