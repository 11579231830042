import NutriCapa from '../images/nutriCapa.png'
import SemiCircunference from '../images/SemiCircunference.svg'
import SemiCircunferenceWhite from '../images/SemiCircunferenceWhite.svg'
import { useEffect, useRef } from 'react'

import { AnimatePresence, motion } from 'framer-motion'

import clsx from 'clsx'

import VerdurasLegumes1 from '../images/verdurasLegumes1.png'
import VerdurasLegumes2 from '../images/verdurasLegumes2.png'
import Polifenois1 from '../images/polifenois1.png'
import Polifenois2 from '../images/polifenois2.png'
import FibrasAgua2 from '../images/fibrasAgua2.png'

import { useFood } from '../context/foods.context'
import Button from '../components/Button'



const Main = () => {
  const{food, handleChangeFood} = useFood()
  const textRef = useRef<HTMLDivElement>(null)

  console.log(food)
  function handleFoodToDefault() {
    handleChangeFood('none')
  }

  useEffect(() => {
    const currentDescriptionRef = textRef.current
    // function handleClickOutside(event: MouseEvent) {
    //   if (
    //     currentDescriptionRef &&
    //     !currentDescriptionRef.contains(event.target as Node)
    //   ) {
    //     handleFoodToDefault()
    //   }
    // }

    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        handleFoodToDefault()
      }
    }

    if (
      (food?.food !== "none") &&
      currentDescriptionRef
    ) {
      // document.addEventListener('click', handleClickOutside)
      document.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      if (
        (food?.food !== 'none') &&
        currentDescriptionRef
      ) {
        // document.removeEventListener('click', handleClickOutside)
        document.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [food])

  return (
    <main className={clsx("-h-[calc(100vh - 136px)] flex flex-1 flex-col items-center justify-center md:flex-row px-6 lg:px-28",
    food?.food === 'Frutas e Verduras' ? "bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))]  from-[#327839] via-[#327839] to-[#44525A]" : "bg-background-color",
    food?.food === 'Polifenóis' ? "bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))]  from-[#784032] via-[#784032] to-[#5A5344]" : "bg-background-color",
    food?.food === 'Água' ? "bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))]  from-[#325B78] via-[#325B78] to-[#5A5A44]" : "bg-background-color",
    )}
    
    >
      
      {
        food?.food === "none" ? 
        <div className="absolute w-full flex items-center justify-center h-[124px] bg-primary-500 top-44">
          <AnimatePresence mode="wait">
            <motion.div
              key={0}
              initial={{ y: 0, opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ y: 0, opacity: 0 }}
              transition={{ duration: 0.5 }}
              className='flex flex-row items-center justify-center'
            >
              {/* <div className='absolute left-0 z-10 ml-48 flex w-[232px] h-[232px] border-[3px] border-primary-500 rounded-full'> */}
              <div className='absolute -top-[72px] z-10 ml-48 left-0 hidden xl:flex'>
                <img src={NutriCapa} alt='Foto da Nutricionista Eliete Rodrigues sorrindo, cabelo com cachos preto, pele clara, olhos de cor preta, camisa branca.' width={256}
                  placeholder="blur"
                />
              </div>

              {/* <div className='absolute left-0 z-0 ml-[200px] flex w-[216px] h-[216px] border-2 border-background-color rounded-full opacity-50'></div> */}

              {/* <div className='absolute ml-48 flex w-[256px] h-[256px] border-4 border-background-color rounded-full left-0'>

              </div> */}

              <div className='w-96'>
                <span className="flex text-background-color font-alt text-2xl lg:text-[28px]">
                    Nutricionista Funcional Especializada
                </span>

                <span className='flex items-center justify-center text-background-color font-alt text-2xl lg:text-[28px]'>
                  em Modulação Intestinal
                </span>
              </div>



            </motion.div>
          </AnimatePresence>
        </div>
        :
        <></>
      }

      {
        food?.food != "none" ? 
        <div className="absolute w-full flex items-center justify-center h-32 top-44">
        <AnimatePresence mode="wait">
          <motion.div
            key={0}
            initial={{ y: 0, opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ y: 0, opacity: 0 }}
            transition={{ duration: 0.5 }}
            className='flex flex-row items-center justify-center'
          >
            <div className='w-96'>
              <span className="flex text-background-color font-alt text-2xl lg:text-[28px]">
                  Alimentos de um
              </span>

              <span className='flex items-center justify-center text-background-color font-alt text-2xl lg:text-[28px]'>
                Intestino Saudável
              </span>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
      :<></>
      }
      {/* <div className="absolute top-[40%] flex w-[934px] h-[934px] border-4 border-primary-500 rounded-full">

      </div> */}

      {/* Textos do Semicirculo */}
      <div className='hidden z-30 absolute items-center justify-center bottom-0 lg:flex HIDDEN-HEIGHT'>
        <svg width="775" height="422" viewBox="0 0 775 382" xmlns="http://www.w3.org/2000/svg">
          <path id='curve' fill="transparent" d="M772.189 381.162C772.19 380.942 772.19 380.721 772.19 380.5C772.19 171.493 599.81 2 387.095 2C174.38 2 2 171.493 2 380.5C2 380.721 2.00019 380.942 2.00058 381.162H0.000573454C0.000191203 380.942 0 380.721 0 380.5C0 170.356 173.308 0 387.095 0C600.882 0 774.19 170.356 774.19 380.5C774.19 380.721 774.19 380.942 774.189 381.162H772.189Z"/>
            {food?.food === "Frutas e Verduras" ? <circle cx="385" cy="22" r="8" fill="#A6A5A5" stroke="white" strokeWidth="2"/> : <></>}
            {food?.food === "Polifenóis" ? <circle cx="550" cy="56" r="8" fill="#A6A5A5" stroke="white" strokeWidth="2"/> : <></>}
            {food?.food === "Água" ? <circle cx="230" cy="53" r="8" fill="#A6A5A5" stroke="white" strokeWidth="2"/> : <></>}

            <a className='cursor-pointer text-white hover:opacity-50 transition-opacity' onClick={() => handleChangeFood("Água")}>
              <text x='410' fill={food?.food !== 'none' ? 'white' : '#2E3213'}>
                <textPath xlinkHref="#curve" textAnchor="start" startOffset="50%">
                  <tspan dy="0">Água</tspan>
                </textPath>
              </text>
            </a>

            <a className='cursor-pointer hover:opacity-50 transition-opacity' onClick={() => handleChangeFood("Frutas e Verduras")}>
              <text x='540' fill={food?.food !== 'none' ? 'white' : '#2E3213'}>
                <textPath xlinkHref="#curve" textAnchor="start" startOffset="50%">
                  <tspan dy="0">Frutas e Verduras</tspan>
                </textPath>
              </text>
            </a>

            <a className='cursor-pointer hover:opacity-50 transition-opacity' onClick={() => handleChangeFood("Polifenóis")}>
              <text x='750' fill={food?.food !== 'none' ? 'white' : '#2E3213'}>
                <textPath xlinkHref="#curve" textAnchor="start" startOffset="50%">
                  <tspan dy="0">Polifenóis</tspan>
                </textPath>
              </text>
            </a>
        </svg>
      </div>



      <div className='lg:hidden w-full absolute'>
        <div className='lg:hidden flex flex-row items-center justify-center gap-x-12 mb-2'>
          <a className={`cursor-pointer ${food?.food === 'none' ? 'text-primary-500' : 'text-white'} hover:opacity-50 transition-opacity`} onClick={() => handleChangeFood("Água")}>
            <span>Água</span>
          </a>
          <a className={`cursor-pointer ${food?.food === 'none' ? 'text-primary-500' : 'text-white'} hover:opacity-50 transition-opacity`} onClick={() => handleChangeFood("Frutas e Verduras")}>
            <span>Frutas e Verduras</span>
          </a>
          <a className={`cursor-pointer ${food?.food === 'none' ? 'text-primary-500' : 'text-white'} hover:opacity-50 transition-opacity`} onClick={() => handleChangeFood("Polifenóis")}>
            <span>Polifenóis</span>
          </a>
        </div>

        <hr className='border-[1.5px] border-primary-500 mb-6'></hr>
        <hr className='border-1 border-primary-500'></hr>
      </div>

      <div className='absolute flex flex-col items-center justify-center bottom-32 lg:bottom-0 HIDDEN-HEIGHT'>
        <img className='hidden lg:block' src={food?.food === "none" ? SemiCircunference : SemiCircunferenceWhite} alt=''/>

        <div className='z-40 h-2/3 gap-y-4 lg:absolute flex flex-col text-center items-center justify-center w-[500px] text-base font-light mt-16 px-8' ref={textRef}>
          {food?.text}

          <div className='h-1/3 flex items-center'>
            <Button>Fale Comigo</Button>
          </div>

        </div>
      </div>

      <div>
      {food?.food === "Frutas e Verduras" ?
        <AnimatePresence mode="wait">
          <motion.div
            key={food?.food}
            initial={{ y: 0, opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ y: 0, opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <div className='absolute top-36 left-0'>
              <img src={VerdurasLegumes1} alt='' width={423}
                placeholder="blur"
              />
            </div>

            <div className='absolute bottom-0 right-0'>
              <img src={VerdurasLegumes2} alt='' width={420}
                placeholder="blur"
              />
            </div>
          </motion.div>
        </AnimatePresence>
        :
        <></>
      }

      {food?.food === "Polifenóis" ?
        <AnimatePresence mode="wait">
          <motion.div
            key={food?.food}
            initial={{ y: 0, opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ y: 0, opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <div className='absolute bottom-0 left-0'>
              <img src={Polifenois1} alt='' width={338}
                placeholder="blur"
              />
            </div>

            <div className='absolute top-6 right-0'>
              <img src={Polifenois2} alt='' width={380}
                placeholder="blur"
              />
            </div>
          </motion.div>
        </AnimatePresence>
        :
        <></>
      }

      {food?.food === "Água" ?
        <AnimatePresence mode="wait">
          <motion.div
            key={food?.food}
            initial={{ y: 0, opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ y: 0, opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <div className='z-30 absolute bottom-0 left-0'>
              <img src={FibrasAgua2} alt=''height={219}
                placeholder="blur"
              />
            </div>
          </motion.div>
        </AnimatePresence>
        :
        <></>
      }
      </div>
    </main>
  )
}

export default Main