'use client'

import clsx from "clsx"

import KiwiImage from '../images/kiwi.png'
import BiobalanceIcon from '../images/BiobalanceIcon.svg'
import Button from "../components/Button"
import { Article, ClockClockwise, Dna, ForkKnife, Laptop, Leaf, Strategy, WhatsappLogo } from "@phosphor-icons/react"

const Service = () => {
  return (
    <section id="acompanhamento" className="mb-36">
    <div className={clsx("flex flex-1 flex-col justify-center px-6 lg:px-28")}>
      {/* <div className=""> */}
      <h1 className="font-alt mb-8">Acompanhamento</h1>

      <div className="flex flex-col items-center mb-8">
        <div className="flex flex-col gap-y-8 my-8">
          <h2 className="flex flex-row gap-2 font-normal"><ForkKnife size={24} weight="duotone"/> Ofereço um Tratamento Nutricional Funcional Integrativo</h2>
          <h2 className="flex flex-row gap-2 font-normal"><Strategy size={24} weight="duotone"/> Estratégia Nutricional de Acordo com as SUAS Necessidades</h2>
          <h2 className="flex flex-row gap-2 font-normal"><Laptop size={24} weight="duotone"/> Atendimento 100% Online</h2>
        </div>
      </div>

      <div className="hidden md:flex flex-row justify-between">
        <div>
          <span className="text-4xl">8 </span> <span className="text-2xl font-light uppercase">Semanas</span>
        </div>

        <div>
          <span className="text-4xl">12 </span> <span className="text-2xl font-light uppercase">Semanas</span>
        </div>
      </div>
    </div>

    <div className="w-full flex flex-col md:flex-row justify-between">
        <div className='w-full md:w-[40%] flex flex-col mt-2'>
            <div className="flex items-end justify-start md:hidden gap-x-2 ml-8">
              <span className="text-4xl">8 </span> <span className="text-2xl font-light uppercase">Semanas</span>
            </div>

            <div className="bg-primary-500 h-12 md:rounded-tr-lg flex items-center justify-end pr-8 mb-8">
              <h3 className="text-background-color">Acompanhamento Padrão</h3>
            </div>


            <div className="bg-gradient-to-r from-[#696B5C] h-12 flex flex-row items-center mb-2 px-6 lg:px-28 gap-x-2">
              <ClockClockwise size={24} weight="light"/><h4 className="text-primary-600 text-base">Acompanhamento por 8 semanas</h4>
            </div>

            <div className="bg-gradient-to-r from-[#696B5C] h-12 flex flex-row items-center mb-2 px-6 lg:px-28 gap-x-2">
              <WhatsappLogo size={24} weight="light"/><h4 className="text-primary-600 text-base">Assessoria via WhatsApp</h4>
            </div>

            <div className="bg-gradient-to-r from-[#696B5C] h-12 flex flex-row items-center mb-2 px-6 lg:px-28 gap-x-2">
              <Leaf size={24} weight="light"/><h4 className="text-primary-600 text-base">Detox de Intestino, Fígado e Vesícula</h4>
            </div>

            <div className="bg-gradient-to-r from-[#696B5C] h-12 flex flex-row items-center mb-2 px-6 lg:px-28 gap-x-2">
              <Dna size={24} weight="light"/><h4 className="text-primary-600 text-base">Análise de Exames</h4>
            </div>

            <div className="bg-gradient-to-r from-[#696B5C] h-12 flex flex-row items-center mb-2 px-6 lg:px-28 gap-x-2">
              <Article size={24} weight="light"/><h4 className="text-primary-600 text-base">Manutenção e Reavaliações</h4>
            </div>
        </div>

        <div className='w-full md:w-[40%] flex flex-col mt-2'>
            <div className="flex items-end justify-end md:hidden gap-x-2 mr-8">
              <span className="text-4xl">12 </span> <span className="text-2xl font-light uppercase">Semanas</span>
            </div>

            <div className="bg-primary-500 h-12 md:rounded-tl-lg flex items-center pl-6 mb-8">
              <h3 className="text-background-color">Acompanhamento Biobalance</h3>
            </div>

            <div className="bg-gradient-to-l text-base from-purple-500 h-12 flex items-center pl-6 mb-8">
              <h4 className="text-primary-600">Tudo o que o Acompanhamento Padrão Oferece +</h4>
            </div>

            <div className="w-full flex flex-row">
              <img src={BiobalanceIcon} alt="" width={110} />

              <div className="w-full flex-col text-base bg-gradient-to-l from-purple-500 flex items-start justify-center gap-y-4 pl-6">
                <p>Acompanhamento por 12 semanas</p>
                <p>6 encontros via Zoom</p>
                <p>Acompanhamento mais próximo</p>
                <p>Valor diferenciado</p>
              </div>
            </div>
        </div>
      </div>

    <div className="w-full bg-primary-500 h-44 md:h-[148px] flex flex-row mt-32 md:mt-44 items-center px-6 lg:px-28 mb-16">
      <div>
        <img src={KiwiImage} alt="Kiwi" height={350} placeholder="blur"/>
      </div>

      <div className="w-full flex flex-col items-center justify-between md:flex-row gap-y-4">
        <p className="text-xl md:px-8 md:text-2xl font-light text-background-color font-alt">
          Para mais detalhes sobre os acompanhamentos e qual o valor do investimento a ser realizado, clique no botão ao lado
        </p>

        <div className="">
          <Button>Falar com a Nutri</Button>
        </div>

      </div>

    </div>
    </section>
  )
}
export default Service