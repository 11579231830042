import clsx from "clsx"

import IntroImage from '../images/introImage.png'
import Button from "../components/Button"
import { Quotes } from "@phosphor-icons/react"

const Intro = () => {
  return (
    <main className={clsx("min-h-screen flex flex-1 flex-col justify-center lg:px-28")}>
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-[55%] xl:w-2/3">
          <h1 className="font-alt md:mb-8 px-6">O quanto você está comprometido(a) com a sua saúde?</h1>

          {/* <div className="flex md:hidden absolute -bottom-[448px] right-0 w-[350px] lg:w-[480px] xl:w-[544px]"> */}
          <div className="w-full flex justify-end">
            <div className="md:hidden w-[350px] lg:w-[480px] xl:w-[544px]">
              <img 
                src={IntroImage} 
                alt='Casal de idosos fazendo suco'
                placeholder="blur"
                // priority={true}
              />
            </div>
          </div>



        <div className="px-6">
          <div className="font-light">

          <p>
          Se você estiver pronto(a) para fazer uma verdadeira transformação na sua saúde e encontrar soluções reais para seus sintomas gastrointestinais você está no lugar certo. Eu estou aqui para te mostrar o caminho e soluções que ajudarão a aliviar e controlar os seus sintomas e a te ajudar a recuperar o controle de sua saúde.
          <br /><br />
          Luto por uma alimentação que traga a autonomia física e mental para que você viva bem consigo mesma. Que você coma o que ama ao mesmo tempo em que nutri suas células.
          </p>

          </div>

          <div className="mt-8 flex items-center flex-col">
          <Button>Estou 100% Comprometido(a)</Button>
          </div>

          <div className="hidden md:flex flex-row mt-16 gap-2 justify-start">
          <Quotes size={18} color="#2E3213" weight="fill" style={{ transform: 'rotate(180deg)' }}/>
          <p className="font-alt text-xl lg:text-2xl">
          A mudança só acontece com a mudança</p>
          <Quotes size={18} color="#2E3213" weight="fill"/>
          </div>
          </div>


        </div>
        <div className="hidden md:flex absolute right-0 w-[350px] lg:w-[480px] xl:w-[544px]">
            <img src={IntroImage} alt='Casal de idosos fazendo suco'/>
          </div>
      </div>




      <div className="hidden md:flex flex-row mt-8 gap-2 justify-start">
        <Quotes size={18} color="#2E3213" weight="fill" style={{ transform: 'rotate(180deg)' }}/>
        <p className="font-alt text-xl lg:text-2xl ">
          Quando o assunto é intestino, parar de fazer o errado antes de fazer o certo faz toda diferença
        </p>
        <Quotes size={18} color="#2E3213" weight="fill"/>
      </div>

      <div className="hidden md:flex flex-row mt-8 gap-2 justify-start">
        <Quotes size={18} color="#2E3213" weight="fill" style={{ transform: 'rotate(180deg)' }}/>
        <p className="font-alt text-xl lg:text-2xl">
          Se você não está se nutrindo, está nutrindo os fungos e más bactérias que vivem dentro você
        </p>
        <Quotes size={18} color="#2E3213" weight="fill"/>
      </div>
    </main>
  )
}
export default Intro